<template>
  <div :class="outer">
    <div class="inner">
      <masthead title="Change Password" :show-search="false" />
      <b-form>
        <b-form-group
          v-if="usingPassword"
          data-cy="currentPasswordLbl"
          label="Current Password"
          label-for="currentPassword"
          auto-suggest="current-password"
        >
          <b-form-input
            v-model="form.currentPassword"
            data-cy="currentPassword"
            :type="inputType()"
            required
          />
        </b-form-group>
        <b-form-group
          data-cy="newPasswordLbl"
          label="New Password"
          label-for="newPassword"
        >
          <b-form-input
            v-model="form.newPassword"
            data-cy="newPassword"
            :type="inputType()"
            auto-suggest="new-password"
            required
          />
        </b-form-group>
        <b-form-group
          data-cy="confirmPasswordLbl"
          label="Confirm Password"
          label-for="confirmPassword"
        >
          <b-form-input
            v-model="form.confirmPassword"
            data-cy="confirmPassword"
            :type="inputType()"
            auto-suggest="confirm-password"
            required
          />
        </b-form-group>
        <b-form-checkbox
          v-model="form.showPassword"
          class="mb-2 mr-sm-2 mb-sm-3 float-right"
        >
          <span data-cy="showPasswordLbl">Show Password</span>
        </b-form-checkbox>
        <br>
        <ul>
          <li
            v-for="rule in rules"
            :key="rule.id"
            :style="{'text-decoration': rule.state ? 'line-through' : ''}"
            :class="rule.state ? 'valid' : ''"
          >
            {{ rule.description }}
          </li>
        </ul>
        <b-button
          block
          :disabled="!valid"
          variant="primary"
          class="mt-2"
          data-cy="submitBtn"
          @click="saveChanges"
        >
          Apply Changes
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import Masthead from '@/components/shared/Masthead'
import { mapGetters, mapActions } from 'vuex'
import http from '@/http'

export default {
  name: 'ChangePassword',
  components: { Masthead },
  props: {
    token: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        showPassword: false,
      },
    }
  },
  computed: {
    ...mapGetters('website', ['website']),
    outer() {
      return !this.$store.state.session.token ? 'outer' : 'default'
    },
    usingPassword() {
      // True if the auth method is password. Alternatively, the user has an emailed reset token.
      return !this.token
    },
    valid() {
      return this.rules.filter(r => !r.state).length === 0
    },
    rules: {
      get() {
        return [
          {
            id: 0,
            description: '8 or more characters',
            state: this.form.newPassword.length >= 8,
          },
          {
            id: 1,
            description: 'Must contain a number',
            state: this.form.newPassword.search(/[0-9]/) > -1,
          },
          {
            id: 2,
            description: 'Must contain an uppercase & lowercase letter',
            state:
              this.form.newPassword.search(/[a-z]/) > -1 &&
              this.form.newPassword.search(/[A-Z]/) > -1,
          },
          {
            id: 3,
            description: 'Must contain one symbol (`!@#$%^&*)',
            state: this.form.newPassword.search(/[^ \w]/) > -1,
          },
          {
            id: 4,
            description: 'Passwords must match',
            state:
              this.form.newPassword === this.form.confirmPassword &&
              this.form.confirmPassword.length > 0,
          },
        ]
      },
    },
    params() {
      let params = {}
      if (this.usingPassword) {
        params = {
          new_password: this.form.newPassword,
          old_password: this.form.currentPassword,
        }
      } else {
        params = {
          password: this.form.newPassword,
          reset_token: this.token,
        }
      }

      return params
    },
    path() {
      // The API route varies based on auth method: original password vs password-reset-token.
      return this.usingPassword ? `client/account/change_password` : `client/auth/reset_password`
    },
    websiteSubdomain() {
      const urlParts = location.hostname.split('.')
      return urlParts.length > 0 ? urlParts[0] : ''
    },
    websiteBillingPhone() {
      if (this.website) {
        return this.website.phone_numbers.find((a) => a.kind === 'billing').phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      }

      return ''
    },
  },
  watch: {
    async token() {
      // If we have a token we are resetting the password and thus have no session data and need to get the website via the subdomain route.
      if (!this.website && this.websiteSubdomain && this.websiteSubdomain !== 'localhost') {
        const response = await http.get(`/client/websites/by_subdomain/${this.websiteSubdomain}`)

        await this.getWebsite({ id: response.data.result.id })
      }
    },
  },
  async mounted() {
    if (this.$store.state.session.websiteId) {
      // If we have a valid session it implies we are changing the password within the account and can use the session websiteId.
      await this.getWebsite({ id: this.$store.state.session.websiteId })
    }
  },
  methods: {
    ...mapActions('website', ['getWebsite']),
    inputType() {
      return this.form.showPassword ? 'text' : 'password'
    },
    async saveChanges() {
        await http.post(this.path, this.params).then(() => {
          this.$router.push('/')
        }).catch(() => {
          const message = `There was a problem saving your new password. Please call us to reset your password.  ${this.websiteBillingPhone}`

          this.$bvToast.toast(message, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style-type: none;
  padding-inline-start: 0;
}

.outer {
  width: 200%!important;
  min-width: 100%!important;
}
.default {
  width: 100%!important;
}
.inner {
  display: table!important;
  margin: 0 auto;
}
</style>
