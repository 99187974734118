var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.outer }, [
    _c(
      "div",
      { staticClass: "inner" },
      [
        _c("masthead", {
          attrs: { title: "Change Password", "show-search": false },
        }),
        _c(
          "b-form",
          [
            _vm.usingPassword
              ? _c(
                  "b-form-group",
                  {
                    attrs: {
                      "data-cy": "currentPasswordLbl",
                      label: "Current Password",
                      "label-for": "currentPassword",
                      "auto-suggest": "current-password",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        "data-cy": "currentPassword",
                        type: _vm.inputType(),
                        required: "",
                      },
                      model: {
                        value: _vm.form.currentPassword,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "currentPassword", $$v)
                        },
                        expression: "form.currentPassword",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-form-group",
              {
                attrs: {
                  "data-cy": "newPasswordLbl",
                  label: "New Password",
                  "label-for": "newPassword",
                },
              },
              [
                _c("b-form-input", {
                  attrs: {
                    "data-cy": "newPassword",
                    type: _vm.inputType(),
                    "auto-suggest": "new-password",
                    required: "",
                  },
                  model: {
                    value: _vm.form.newPassword,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "newPassword", $$v)
                    },
                    expression: "form.newPassword",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-form-group",
              {
                attrs: {
                  "data-cy": "confirmPasswordLbl",
                  label: "Confirm Password",
                  "label-for": "confirmPassword",
                },
              },
              [
                _c("b-form-input", {
                  attrs: {
                    "data-cy": "confirmPassword",
                    type: _vm.inputType(),
                    "auto-suggest": "confirm-password",
                    required: "",
                  },
                  model: {
                    value: _vm.form.confirmPassword,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "confirmPassword", $$v)
                    },
                    expression: "form.confirmPassword",
                  },
                }),
              ],
              1
            ),
            _c(
              "b-form-checkbox",
              {
                staticClass: "mb-2 mr-sm-2 mb-sm-3 float-right",
                model: {
                  value: _vm.form.showPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "showPassword", $$v)
                  },
                  expression: "form.showPassword",
                },
              },
              [
                _c("span", { attrs: { "data-cy": "showPasswordLbl" } }, [
                  _vm._v("Show Password"),
                ]),
              ]
            ),
            _c("br"),
            _c(
              "ul",
              _vm._l(_vm.rules, function (rule) {
                return _c(
                  "li",
                  {
                    key: rule.id,
                    class: rule.state ? "valid" : "",
                    style: {
                      "text-decoration": rule.state ? "line-through" : "",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(rule.description) + "\n        "
                    ),
                  ]
                )
              }),
              0
            ),
            _c(
              "b-button",
              {
                staticClass: "mt-2",
                attrs: {
                  block: "",
                  disabled: !_vm.valid,
                  variant: "primary",
                  "data-cy": "submitBtn",
                },
                on: { click: _vm.saveChanges },
              },
              [_vm._v("\n        Apply Changes\n      ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }